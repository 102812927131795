/** @format */

import { AnimatedCardEntry } from '@/types/shared';
import { FunctionComponent as FC, useContext } from 'react';
import { breakpointUpTo } from 'src/theme/mixins';
import styled, { ThemeContext } from 'styled-components';
import { Container, ContainerWrapper } from '../ui/Layout';
import { DataNumbers, DataUnitMeasure, H4, Kicker, Subtitle } from '../ui/Typography';

function splitIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
    array.slice(index * chunkSize, index * chunkSize + chunkSize),
  );
}

export const SustainabilityNumbersSection: FC<Props> = ({ title, icons, numbers, description, negativeMargin }) => {
  const theme = useContext(ThemeContext);
  const numberChunks = splitIntoChunks(numbers, 2);

  return (
    <CustomContainerWrapper $color={theme!.color.white} $negativeMargin={negativeMargin}>
      <CustomContainer>
        <Wrapper>
          <H4 as="h2">{title}</H4>
          <Grid>
            {icons.map((icon, index) => (
              <ColumnWrapper key={icon.fields.title.trim() + index}>
                <IconWrapper>
                  <div dangerouslySetInnerHTML={{ __html: icon.fields.svg }} />
                  <H4 as="h3">{icon.fields.title}</H4>
                </IconWrapper>
                {numberChunks[index].map((statistics, numerIndex) => (
                  <>
                    <Data key={statistics.label}>
                      <DataNumber $color={theme!.color.white}>
                        {statistics.prefix && (
                          <DataUnitMeasure color={theme!.color.white}>{statistics.prefix}</DataUnitMeasure>
                        )}
                        {statistics.statisticsNumber}{' '}
                        {statistics.value && (
                          <DataUnitMeasure color={theme!.color.white}>{statistics.value}</DataUnitMeasure>
                        )}
                      </DataNumber>
                      <Subtitle $color={theme!.color.yellow}>{statistics.label}</Subtitle>
                    </Data>
                    {index === icons.length - 1 && (
                      <DescriptionWrapper>
                        <Plus>+</Plus>
                        <Kicker>{description}</Kicker>
                      </DescriptionWrapper>
                    )}
                  </>
                ))}
              </ColumnWrapper>
            ))}
          </Grid>
        </Wrapper>
      </CustomContainer>
    </CustomContainerWrapper>
  );
};

const CustomContainerWrapper = styled(ContainerWrapper)<{ $negativeMargin: boolean }>`
  ${({ $negativeMargin }) =>
    $negativeMargin &&
    `
    background-color:transparent;
    z-index: 2;
    position: relative;
    margin-bottom: calc(clamp(0rem, 5.5833rem + 0.7407vw, 3.75rem) * -1);

    `}

  ${breakpointUpTo.lg`
      margin-bottom: 0;
    `}
`;

const CustomContainer = styled(Container)`
  ${breakpointUpTo.md`
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  margin-top: clamp(5.75rem, 5.5833rem + 0.7407vw, 6.25rem); // TO BE REMOVED BEFORE MERGE WITH DEVELOP
  padding-top: clamp(3.75rem, 3.0833rem + 2.963vw, 5.75rem);
  padding-bottom: clamp(3.95rem, 3.4967rem + 2.0148vw, 5.31rem);
  padding-inline: clamp(1.88rem, 0.1733rem + 7.5852vw, 7rem);
  background-color: ${({ theme }) => theme.color.teal.dark};
  border-radius: clamp(2.3125rem, 1.625rem + 3.0556vw, 4.375rem);

  h2 {
    text-align: center;
    color: ${({ theme }) => theme.color.white};
    padding-bottom: clamp(3.75rem, 3.5833rem + 0.7407vw, 4.25rem);
  }
`;

const ColumnWrapper = styled.div`
  &:nth-child(1),
  &:nth-child(2) {
    border-right: 1px solid ${({ theme }) => theme.color.accent.gray};
    ${breakpointUpTo.lg`
        border-right: unset;
    `}
  }

  & {
    ${breakpointUpTo.lg`
        width: 14rem;
        margin-inline:auto;
        `}
  }

  &:nth-child(1) {
    > div {
      padding-inline: 0 clamp(0rem, -12.2229rem + 19.7143vw, 5.52rem);
      width: 100%;
      ${breakpointUpTo.lg`
        width: 14rem;
        margin-inline:auto;
        `}
    }
  }

  &:nth-child(3) {
    > div {
      padding-inline: clamp(0rem, -12.2229rem + 19.7143vw, 5.52rem) 0;
      width: 100%;
    }
  }

  &:nth-child(2) {
    > div {
      padding-inline: clamp(0rem, -12.2229rem + 19.7143vw, 5.52rem);
      width: 100%;
    }
  }

  > div:nth-child(2) {
    padding-top: clamp(0rem, -0.75rem + 3.3333vw, 2.25rem);
  }
  > div:nth-child(3) {
    padding-top: clamp(0.94rem, 0.67rem + 1.2vw, 1.75rem);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${breakpointUpTo.lg`
    grid-template-columns: 1fr;
    gap:3.75rem;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: clamp(1.25rem, 1.04rem + 0.9333vw, 1.88rem);
  padding-bottom: clamp(0.94rem, 0.65rem + 1.2889vw, 1.81rem);
  border-bottom: 1px solid ${({ theme }) => theme.color.accent.gray};
  ${breakpointUpTo.lg`
    justify-content:center;
    border-bottom: unset;
  `}

  h3,
  h4 {
    color: ${({ theme }) => theme.color.teal.light};
  }
`;

const Data = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    font-weight: 300;
    max-width: 16.8rem;
  }
`;

const DataNumber = styled(DataNumbers)`
  display: flex;
  align-items: flex-end;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.25rem;
  height: 11rem;
  color: ${({ theme }) => theme.color.yellow};

  p {
    padding-bottom: 0;
    max-width: 9.4375rem;

    color: ${({ theme }) => theme.color.white};
  }
  ${breakpointUpTo.lg`
  text-align:center;
    gap: 0;
    flex-direction:column;
    height:auto;
  `}
`;

const Plus = styled.span`
  font-size: 4.375em;
  font-style: normal;
  font-weight: 300;
  line-height: 3.79213rem; /* 145.429% */
`;

type Props = {
  title: string;
  icons: AnimatedCardEntry[];
  numbers: { statisticsNumber: number; label: string; value: string; prefix: string }[];
  description: string;
  negativeMargin: boolean;
};
